import {Component} from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Radio } from "antd";
import React from "react";
import axios from "axios";

/**
 * 前端静态资源
 */
class FrontStaticResource extends Component {
    state = {
        frontStaticResource: {
            "env": "test",
            "mode": "mobile",
            "appCssUrl":         "appCss1ss",
            "appJsUrl":          "appJs1",
            "manifestJsUrl":     "manifest1",
            "vendorJsUrl":       "vendor1"
        }
    }

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.getPageStaticResource("test");
    }

    /**
     * 查询页面静态资源API
     */
    getPageStaticResource(env) {
        let params = {
            env: env
        }
        axios({
            url: "/api/index/back/getPageStaticResource",
            params
        }).then(resp => {
            let frontStaticResource = resp.data.data;
            this.setState({
                "frontStaticResource": frontStaticResource
            })
            console.log("getPageStaticResource, state", this.state)
        });
    }

    /**
     * 提交表单
     **/
    handleSubmit = (env, e) => {
        // 调用api
        let params = this.state.frontStaticResource;
        this.setState({
            "frontStaticResource": params
        });
        console.log(params);
        axios.post("/api/index/back/updatePageStaticResource", this.state.frontStaticResource)
        message.info("提交成功")
    };

    /**
     * 同步前端的测试环境代码到正式环境
     * */
    /*syncTestToPro(){
        axios.post("/api/index/back/syncPageStaticResourceToPro").then(resp => {
            message.info("同步完成");
        })
    }*/

    /**
     * 处理表单change事件
     * @param appCssUrl
     * @param e
     */
    handleChange = (key, e) => {
        // 更新this.state.frontStaticResource的属性值
        let frontStaticResource = Object.assign(this.state.frontStaticResource, {
            [key]: e.target.value
        });
        this.setState({
            frontStaticResource: frontStaticResource
        });
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 15 },
                sm: { span: 15 },
            }
        };

        return (
            <Form {...formItemLayout} >
                <Form.Item label={"环境"}>
                    <Radio.Group value={this.state.frontStaticResource.env} onChange={this.handleChange.bind(this, "env")}>
                        <Radio value={"test"}>测试</Radio>
                        <Radio value={"pro"}>正式</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={"发布产品"}>
                    <Radio.Group value={this.state.frontStaticResource.mode} onChange={this.handleChange.bind(this, "mode")}>
                        <Radio value={"pc"}>pc</Radio>
                        <Radio value={"mobile"}>mobile</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={"appCssUrl"}>
                    <Input value={this.state.frontStaticResource.appCssUrl} onChange={this.handleChange.bind(this, "appCssUrl")}/>
                </Form.Item>
                <Form.Item label={"appJsUrl"}>
                    <Input value={this.state.frontStaticResource.appJsUrl} onChange={this.handleChange.bind(this, "appJsUrl")}/>
                </Form.Item>
                <Form.Item label={"manifestJsUrl"}>
                    <Input value={this.state.frontStaticResource.manifestJsUrl} onChange={this.handleChange.bind(this, "manifestJsUrl")}/>
                </Form.Item>
                <Form.Item label={"vendorJsUrl"}>
                    <Input value={this.state.frontStaticResource.vendorJsUrl} onChange={this.handleChange.bind(this, "vendorJsUrl")}/>
                </Form.Item>
                <Form.Item label={" "} colon={false}>
                    <Button type="primary"  onClick={this.handleSubmit.bind(this)}>GO-发布程序</Button>
                </Form.Item>
            </Form>
        );
    }
}

let FrontStaticResourceHoc = Form.create({ name: 'normal_login' })(FrontStaticResource);
export default FrontStaticResourceHoc;
