import React from 'react';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Button, Input, message } from 'antd';

const { Column } = Table;
export default class BannedKeyword  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            distributorList: [],
            keywordInput: ""
        }
    };


    componentDidMount() {//组件已经完全挂载到网页上才会调用被执行
        this.getDistributorData();
    }

    /**
     * 请求关键词列表信息;
     */
    getDistributorData() {
        axios('/api/keyword/list', {}).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            let list = data.data;
            this.setState({
              distributorList: list
            });
          }
        });
    }

    keywordInput(e) {
        let val = e.target.value;
        this.setState({
            keywordInput: val
        });
    }

    createBannedKeyword() {
        let param = {
            name: this.state.keywordInput
        }
        axios.post("/api/keyword/add", param).then(res => {
            let data = res.data;
            if (data.code === 1) {
              message.success("添加成功！");
              this.getDistributorData();
            } else {
              message.error(`${data.msg}`);
            }
        });
    }

    render() {
        return (
            <div>
                {/* 工具栏 */}
                <div className="toolbar">
                    {/*新增渠道按钮*/}
                    <Input style={{ width: 1000, marginTop: 10, marginLeft: 5 }} value={this.state.keywordInput} onChange={this.keywordInput.bind(this)} placeholder="输入关键词（多个关键词可以“、”隔开）" allowClear />
                    <Button style={{ marginBottom: 20 }} icon={<PlusOutlined />} onClick={this.createBannedKeyword.bind(this)}>新增</Button><br/>
                </div>

                {/* Table start */}
                <Table bordered={true} dataSource={this.state.distributorList} rowKey={row=>row.id}>
                    <Column align="center" title="ID" dataIndex="id" />
                    <Column align="center" title="名称" dataIndex="name" />
                </Table>
                {/* Table end */}

                
            </div>
        );
    }
}
