import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Radio, message } from 'antd';
import Axios from "axios";

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
};
/**
 * 创建渠道form表单初始化
 */
const distributorCreateForm = {
    code: "",
    title: "",
    speechcraftContent: "",
    newsApiUpstream: "oupeng",
    detailRecommendApiUpstream: "oupeng",
    domain: "https://n.opgirl.cn",
    status: "1",
    adConfig: {},
    adLayerStatus: 1,
    adDownLayerStatus: 2,
    speechcraftStatus: 2,
    speechcraftChance: 100,
    detailRecommendNewsCount: 11,
    feedStatus: 1,
    tongjiBaidu: "",
    tongjiCnzz: "",
    tongjiBaiduChance: 100,
    tongjiCnzzChance: 100,
    newsShowCount: 0,
    detailsPageEntryJump: ""
}

/**
 * 渠道创建组件
 */
class DistributorCreate extends React.Component{

    /**
     * 构造方法
     * @param props
     */
    constructor(props){
        super(props)
        this.state = {
            ...distributorCreateForm
        }
    }

    /**
     * 点击确认按钮的事件
     */
    handleOk(){
        this.subDistributorCerate();

    }

    /**
     * 点击取消按钮的事件
     */
    handleCancel(){
        this.closeModel();
    }

    /**
     * 关闭模态框
     */
    closeModel(){
        this.setState(distributorCreateForm);
        this.props.close();
    }

    /**
     * 表单input过滤一下state值
     */
    handleChange = (name, e) => {
        const { value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    subDistributorCerate() {
        let param = this.state;
        //转为整型
        param.status = parseInt(param.status);
        param.detailRecommendNewsCount = parseInt(param.detailRecommendNewsCount);
        param.tongjiBaiduChance = parseInt(param.tongjiBaiduChance);
        param.tongjiCnzzChance = parseInt(param.tongjiCnzzChance);
        param.speechcraftChance = parseInt(param.speechcraftChance);
        param.newsShowCount = parseInt(param.newsShowCount);

        Axios.post('/api/distributor/back/addDistributor', param).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            message.success('渠道创建成功！');
            // 创建成功后请求渠道信息；
            this.props.getDistributorData();
            //关闭模态框
            this.closeModel();
          } else {
            message.error(`${data.msg}`);
          }
        });
    }

    /**
     * 渲染组件
     */
    render() {
        const { code, title, newsApiUpstream, detailRecommendApiUpstream, domain, status, adDownLayerStatus, speechcraftStatus, speechcraftChance, detailRecommendNewsCount, speechcraftContent, feedStatus, newsShowCount} = this.state;
        return (
            <div>
                {/*新增渠道模态框*/}
                <Modal
                    title="新增渠道"
                    visible={this.props.visible}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}>
                    <Form>
                        <Form.Item labelAlign="left" {...formItemLayout} label="渠道编码">
                            <Input value={code} onChange={this.handleChange.bind(this, 'code')} placeholder="Please input your code" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="渠道标题">
                            <Input value={title} onChange={this.handleChange.bind(this, 'title')} placeholder="Please input your title" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="渠道入口域名">
                            <Input value={domain} onChange={this.handleChange.bind(this, "domain")}/>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="话术文本">
                            <Input value={speechcraftContent} onChange={this.handleChange.bind(this, "speechcraftContent")}/>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="话术概率">
                            <Input type="number" value={speechcraftChance} onChange={this.handleChange.bind(this, 'speechcraftChance')} placeholder="话术概率" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="话术状态">
                            {/* 1启用，2禁用 */}
                            <Radio.Group value={speechcraftStatus} defaultValue="2" onChange={this.handleChange.bind(this, 'speechcraftStatus')} buttonStyle="solid">
                                <Radio.Button value={1}>启用</Radio.Button>
                                <Radio.Button value={2}>禁用</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="资讯Api">
                            <Radio.Group  value={newsApiUpstream} onChange={this.handleChange.bind(this, 'newsApiUpstream')} defaultValue="oupeng" buttonStyle="solid">
                                <Radio.Button value="oupeng">欧朋</Radio.Button>
                                <Radio.Button value="xiguang" disabled>犀光</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="详情页相关推荐Api">
                            <Radio.Group value={detailRecommendApiUpstream} onChange={this.handleChange.bind(this, 'detailRecommendApiUpstream')} defaultValue="oupeng" buttonStyle="solid">
                                <Radio.Button value="oupeng">欧朋</Radio.Button>
                                <Radio.Button value="disifanshi">第四范式</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="渠道状态">
                            {/* 1启用，2禁用，3删除 */}
                            <Radio.Group value={status} defaultValue="1" onChange={this.handleChange.bind(this, 'status')} buttonStyle="solid">
                                <Radio.Button value="1">启用</Radio.Button>
                                <Radio.Button value="2">禁用</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {/* <Form.Item labelAlign="left" {...formItemLayout} label="广告蒙层状态">
                            <Radio.Group value={adLayerStatus} defaultValue="1" onChange={this.handleChange.bind(this, 'adLayerStatus')} buttonStyle="solid">
                                <Radio.Button value={1}>启用</Radio.Button>
                                <Radio.Button value={2}>禁用</Radio.Button>
                            </Radio.Group>
                        </Form.Item> */}
                        <Form.Item labelAlign="left" {...formItemLayout} label="广告下线蒙层状态">
                            {/* 1启用，2禁用 */}
                            <Radio.Group value={adDownLayerStatus} defaultValue="2" onChange={this.handleChange.bind(this, 'adDownLayerStatus')} buttonStyle="solid">
                                <Radio.Button value={1}>启用</Radio.Button>
                                <Radio.Button value={2}>禁用</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="详情页推荐数量">
                            <Input style={{width: 80}} type="number" value={detailRecommendNewsCount} onChange={this.handleChange.bind(this, "detailRecommendNewsCount")}/>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="列表信息流数量\页">
                            <Input style={{width: 80}} type="number" value={newsShowCount} onChange={this.handleChange.bind(this, "newsShowCount")}/>
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="feed流状态">
                            {/* 1启用，2禁用 */}
                            <Radio.Group value={feedStatus} defaultValue="1" onChange={this.handleChange.bind(this, 'feedStatus')} buttonStyle="solid">
                                <Radio.Button value={1}>启用</Radio.Button>
                                <Radio.Button value={2}>禁用</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Form>

                </Modal>
            </div>
        )
    }
}

export default DistributorCreate
