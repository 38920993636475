import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Radio, message } from 'antd';
import Axios from "axios";

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
};

/**
 * 渠道创建组件
 */
class DistributorEdit extends React.Component{

    /**
     * 构造方法
     * @param props
     */
    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    /**
     * 点击确认按钮的事件
     */
    handleOk(){
        this.subDistributorEdit();
    }

    /**
     * 点击取消按钮的事件
     */
    handleCancel(){
        this.closeModel();
    }

    /**
     * 关闭模态框
     */
    closeModel(){
        this.props.close();
    }

    /**
     * 表单input过滤一下state值
     */
    handleChange = (name, e) => {
        const { value } = e.target;
        let data = this.state.distributorConfig;
        data[name] = value;

        this.setState({
            distributorConfig: data
        });
    }

    subDistributorEdit() {
        let param = this.state.distributorConfig;
        // 转为整型
        param.weight = parseInt(param.weight);
        param.status = parseInt(param.status);


        Axios.post('/api/distributor/back/updateRedirect', param).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            message.success('渠道重定向修改成功！');
            // 创建成功后请求渠道信息；
            this.props.getDistributorRedirectData();
            //关闭模态框
            this.closeModel();
          } else {
            message.error(`${data.msg}`);
          }
        });
    }

    componentWillReceiveProps(prevProps) {// 在组件完成更新后立即调用。在初始化时不会被调用。
        let config = prevProps.distributorConfig;
        if(config.id && config.title) {
            this.setState({
                distributorConfig: config
            })
        }
    }

    /**
     * 渲染组件
     */
    render() {
        if (this.state.distributorConfig && this.state.distributorConfig.did) {
            return (
                <div>
                    {/*渠道编辑模态框*/}
                    <Modal
                        title="重定向渠道编辑"
                        visible={this.props.visible}
                        onOk={this.handleOk.bind(this)}
                        onCancel={this.handleCancel.bind(this)}>
                        <Form>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向名称标题">
                            <Input value={this.state.distributorConfig.title} onChange={this.handleChange.bind(this, 'title')} placeholder="Please input your redirect title" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向渠道ID">
                            <Input value={this.state.distributorConfig.did} onChange={this.handleChange.bind(this, 'did')} placeholder="Please input your redirect did" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向权重值">
                            <Input value={this.state.distributorConfig.weight} onChange={this.handleChange.bind(this, 'weight')} placeholder="Please input your redirect weight" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向地址">
                            <Input value={this.state.distributorConfig.redirectUrl} onChange={this.handleChange.bind(this, 'redirectUrl')} placeholder="Please input your redirect url" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="状态">
                            {/* 1启用，2禁用，3删除 */}
                            <Radio.Group value={this.state.distributorConfig.status} defaultValue="1" onChange={this.handleChange.bind(this, 'status')} buttonStyle="solid">
                                <Radio.Button value={1}>启用</Radio.Button>
                                <Radio.Button value={2}>禁用</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Form>

                    </Modal>
                </div>
            )
          } else {
            return null;
        }

    }
}

export default DistributorEdit
