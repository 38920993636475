import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Radio, message, Select } from 'antd';
import Axios from "axios";

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
};

const { confirm } = Modal;
/**
 * 创建渠道form表单初始化
 */
const distributorCreateForm = {
    title: "",
    did: "",
    redirectUrl: "",
    weight: Number,
    status: 1,
}

/**
 * 渠道创建组件
 */
class DistributorCreate extends React.Component{

    /**
     * 构造方法
     * @param props
     */
    constructor(props){
        super(props)
        this.state = {
            ...distributorCreateForm
        }
    }

    /**
     * 点击确认按钮的事件
     */
    handleOk(){
        let param = this.state;
        //转为整型
        param.weight = parseInt(param.weight);
        Axios.post('/api/distributor/back/checkRedirect', param).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            this.subDistributorCerate(param);
        } else if(data.code === 2) {
            let _this = this;
            confirm({
                title: '有警告, 确定要创建该重定向吗?',
                content: `${data.msg}`,
                onOk() {
                    _this.subDistributorCerate(param);
                },
                onCancel() {},
              });
        } else {
            message.error(`${data.msg}`);
        }
        });

    }

    /**
     * 点击取消按钮的事件
     */
    handleCancel(){
        this.closeModel();
    }

    /**
     * 关闭模态框
     */
    closeModel(){
        this.setState(distributorCreateForm);
        this.props.close();
    }

    /**
     * 表单input过滤一下state值
     */
    handleChange = (name, e) => {
        const { value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    /**
     * 编辑提交事件
     */
    subDistributorCerate(param) {

        Axios.post('/api/distributor/back/addRedirect', param).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            message.success('重定向渠道创建成功！');
            // 创建成功后请求渠道信息；
            this.props.getDistributorRedirectData();
            //关闭模态框
            this.closeModel();
          } else {
            message.error(`${data.msg}`);
          }
        });
    }

    /**
     * 渠道搜索
     * @param {*} value
     */
    onChange(value) {
        let data = this.state;
        data.did = value;
        this.setState(data)
        // this.getDistributorRedirectData(value);
    }

    /**
     * 渲染组件
     */
    render() {
        const { title, status, did, redirectUrl, weight} = this.state;
        const { distributorList } = this.props;
        const { Option } = Select;
        return (
            <div>
                {/*新增模态框*/}
                <Modal
                    title="新增重定向"
                    visible={this.props.visible}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}>
                    <Form>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向名称标题">
                            <Input value={title} onChange={this.handleChange.bind(this, 'title')} placeholder="Please input your redirect title" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向渠道ID">
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select distributor title"
                                optionFilterProp="children"
                                onChange={this.onChange.bind(this)}>
                                    {distributorList && Object.keys(distributorList).map((key, i) => (
                                        <Option key={i} value={distributorList[key].id }>{ distributorList[key].title }</Option>
                                    ))}
                            </Select>
                            {did}
                            {/* distributorList<Input value={did} onChange={this.handleChange.bind(this, 'did')} placeholder="Please input your redirect did" /> */}
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向权重值">
                            <Input value={weight} onChange={this.handleChange.bind(this, 'weight')} placeholder="Please input your redirect weight" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="重定向地址">
                            <Input value={redirectUrl} onChange={this.handleChange.bind(this, 'redirectUrl')} placeholder="Please input your redirect url" />
                        </Form.Item>
                        <Form.Item labelAlign="left" {...formItemLayout} label="状态">
                            {/* 1启用，2禁用，3删除 */}
                            <Radio.Group value={status} defaultValue="1" onChange={this.handleChange.bind(this, 'status')} buttonStyle="solid">
                                <Radio.Button value={1}>启用</Radio.Button>
                                <Radio.Button value={2}>禁用</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Form>

                </Modal>
            </div>
        )
    }
}

export default DistributorCreate
