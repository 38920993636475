import React from "react";
import { Input, Modal, message, Form, Button, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import Axios from "axios";
const formItemLayout = {
    labelCol: {xs: { span: 24 },  sm: { span: 4 }},
    wrapperCol: {xs: { span: 24 }, sm: { span: 20 }},
};

const formRef = React.createRef();
/**
 * 渠道创建组件
 */
class DistributorStatistics extends React.Component{
    /**
     * 构造方法
     * @param props
     */
    constructor(props){
        super(props)
        this.state = {

        }
    }

    /**
     * 点击确认按钮的事件
     */
    handleOk(){
        this.subDistributorEdit();
    }

    /**
     * 点击取消按钮的事件
     */
    handleCancel(){
        this.closeModel();
    }

    /**
     * 关闭模态框
     */
    closeModel(){
        this.props.close();
    }

    /**
     * 表单input过滤一下state值
     */
    handleChange = (name, changedValues, allValues) => {
        const value = allValues;
        let data = this.state.distributorConfig;
        data[name] = value;
        this.setState({
            distributorConfig: data
        });

        console.log(data);
    }

    subDistributorEdit() {
        let param = this.state.distributorConfig;

        let jsonStr = param.statisticsJson;
        param.tongjiBaidu = JSON.stringify(jsonStr.baidu) || "";
        param.tongjiCnzz = JSON.stringify(jsonStr.cnzz) || "";
        Axios.post('/api/distributor/back/updateDistributor', param).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            message.success('渠道信息修改成功！');
            // 创建成功后请求渠道信息；
            this.props.getDistributorData();
            //关闭模态框
            this.closeModel();
          } else {
            message.error(`${data.msg}`);
          }
        });
    }

    componentWillReceiveProps(prevProps) {// 在组件完成更新后立即调用。在初始化时不会被调用。
        let config = prevProps.distributorConfig;
        if(config.id && config.title) {
            if((config.tongjiBaidu && typeof(config.tongjiBaidu) === 'string') || (config.tongjiCnzz && typeof(config.tongjiCnzz) === 'string')) {
                let baidu = JSON.parse(config.tongjiBaidu) || [],
                    cnzz = JSON.parse(config.tongjiCnzz) || [];
                config.statisticsJson = {"baidu": baidu,"cnzz": cnzz};
            }
            this.setState({
                distributorConfig: config
            })
        }
    }

    onFinish(values) {
        console.log('Received values of form:', values);
    }

    statisticsDom() {
        return (
          <Form name="dynamic_form_nest_item"
                onFinish={this.onFinish}
                ref={formRef}
                onValuesChange={this.handleChange.bind(this, 'statisticsJson')}
                autoComplete="off">
                <Form.Item labelAlign="left" {...formItemLayout} label="百度统计">
                        <Form.List name="baidu">
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'src']}
                                        fieldKey={[fieldKey, 'key']}
                                        rules={[{ required: true, message: '请填写百度统计地址' }]}
                                    >
                                        <Input placeholder="百度统计地址"/>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'chance']}
                                        fieldKey={[fieldKey, 'key']}
                                        rules={[{ required: true, message: '请填写百度统计概率' }]}
                                    >
                                        <Input placeholder="百度统计安装概率" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Statistics
                                    </Button>
                                </Form.Item>
                                </>
                            )}
                        </Form.List>
                </Form.Item>
                <Form.Item labelAlign="left" {...formItemLayout} label="CNZZ统计">
                        <Form.List name="cnzz">
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'src']}
                                        fieldKey={[fieldKey, 'key']}
                                        rules={[{ required: true, message: '请填写CNZZ统计地址' }]}
                                    >
                                        <Input placeholder="CNZZ统计地址" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'chance']}
                                        fieldKey={[fieldKey, 'key']}
                                        rules={[{ required: true, message: '请填写CNZZ统计概率' }]}
                                    >
                                        <Input placeholder="CNZZ统计安装概率" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Statistics
                                    </Button>
                                </Form.Item>
                                </>
                            )}
                        </Form.List>
                </Form.Item>
            </Form>
        );
    }

    /**
     * 渲染组件
     */
    render() {
        if (this.state.distributorConfig && this.state.distributorConfig.code) {
            const config = this.state.distributorConfig;

            let timer = setInterval(() => {
                if(formRef.current) {
                    clearInterval(timer);
                    // console.log(config.statisticsJson, formRef.current, 'have');
                    let statisticsJson = config.statisticsJson || {"baidu": [],"cnzz": []};
                    formRef.current.setFieldsValue(statisticsJson);
                } else {
                    // console.log(formRef.current, 'no')
                }
            }, 100);
            return (
                <div>
                    {/*渠道编辑模态框*/}
                    <Modal
                        title="渠道统计安装配置"
                        visible={this.props.visible}
                        onOk={this.handleOk.bind(this)}
                        onCancel={this.handleCancel.bind(this)}>
                        <Form>
                            <Form.Item labelAlign="left" {...formItemLayout} label="渠道标题">
                                <Input disabled={true} value={this.state.distributorConfig.title} onChange={this.handleChange.bind(this, 'title')} placeholder="Please input your title" />
                            </Form.Item>
                            <Form.Item labelAlign="left">
                                {this.statisticsDom()}
                            </Form.Item>
                        </Form>

                    </Modal>
                </div>
            )
          } else {
            return null;
        }


    }
}

export default DistributorStatistics;

