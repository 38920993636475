import React from 'react';
import axios from 'axios';
import { DownOutlined, IssuesCloseOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Divider, Button, Menu, Dropdown, Select, Modal, message } from 'antd';
import DistributorRedirectCreate from "./DistributorRedirectCreate.js";
import DistributorRedirectEdit from "./DistributorRedirectEdit.js";
const { Column } = Table;
const { confirm } = Modal;
export default class DistributorRedirect  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            distributorList: [],
            distributorRedirectList: [],
            distributorRedirect: {},
            distributorCreateModelVisible: false,
            distributorEditModelVisiblel: false,
            distributorStatus: 1,
            redirectUrlStyle: {
                width: '350px',
                margin: '0 auto',
                display:'block',
                whiteSpace:'nowrap',
                overflow:'hidden',
                textOverflow:'ellipsis'
            }
        }
    };

    componentDidMount() {//组件已经完全挂载到网页上才会调用被执行
        this.getDistributorRedirectData();
        this.getDistributorData();
    }

    /**
     * 请求渠道信息list;
     */
    getDistributorRedirectData(did, status) {
        //请求信息时， 先清空渠道列表
        this.setState({
            distributorRedirectList: [],
            distributorRedirect: {}
        });
        //axios发送post请求;
        let params = {
            pageNo: 1,
            pageSize: 10000
        }
        params.status = status || 1;
        if(did) params.did = did;
        axios.get('/api/distributor/back/findRedirectList', {params}).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            let list = data.data;
            this.setState({
                distributorRedirectList: list
            });
          }
        });
    }

    /**
     * 请求渠道信息;
     */
    getDistributorData() {
        //请求信息时， 先清空渠道列表
        this.setState({
            distributorList: [],
            distributorConfig: {}
        })
        //axios发送post请求;
        axios.post('/api/distributor/back/findList', {}).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            let list = data.data.distributorList;
            this.setState({
              distributorList: list
            });
          }
        });
    }


    /**
     * 更多操作 下拉菜单元素模板
     */
    menuTemplate() {
        return (
            <Menu>
                <Menu.Item onClick={this.editDistributorRedirect.bind(this)} ><PlusCircleOutlined />编辑</Menu.Item>
                {/* <Menu.Item><Icon type="plus-circle" />状态更新</Menu.Item> */}
                {/* <Menu.Item><Icon type="gift" />重定向列表</Menu.Item> */}
                <Menu.Item onClick={this.deleteDistributorRedirect.bind(this)} style={{color: 'red'}}><IssuesCloseOutlined />删除</Menu.Item>
            </Menu>
        );
    }

    /**
     * 删除渠道重定向
     */
    deleteDistributorRedirect() {
        let _this = this;
        confirm({
            title: 'Do you want to delete these items?',
            content: `是否继续删除 ${this.state.distributorRedirect.title} 重定向`,
            onOk() {
                let params = {
                    id: _this.state.distributorRedirect.id
                }
                axios.delete('/api/distributor/back/deleteRedirect', {params}).then((res) => {
                    let data = res.data;
                    message.info(`${data.msg}`);
                });
            },
            onCancel() {},
        });
    }

    /**
     * 点击更多操作，获取渠道信息
     */
    getConfigRedirectItem = (item) => {
        this.setState({
            distributorRedirect: item
        });
    }

    /**
     * 显示 创建重定向渠道 Model
     **/
    createDistributorRedirect(){
        let model = this.state.distributorCreateModelVisible
        this.setState({
            distributorCreateModelVisible: !model
        })
    }

    /**
     * 编辑重定向渠道 Model
     */
    editDistributorRedirect() {
        let model = this.state.distributorEditModelVisiblel;
        this.setState({
            distributorEditModelVisiblel: !model
        })
    }

    /**
     * 渠道搜索
     * @param {*} value
     */
    onChange(value) {
        this.getDistributorRedirectData(value);
    }

    /**
     * 渠道状态Select
     *
     */
    handleChangeStatus(value) {
        this.getDistributorRedirectData('', value);
    }

    render() {
        const { Option } = Select;
        const { distributorList } = this.state;

        return (
            <div>
                {/* 工具栏 */}
                <div className="toolbar">
                    {/*新增按钮*/}
                    <Button icon={<PlusOutlined />} onClick={this.createDistributorRedirect.bind(this)}>新增</Button>

                    <Select defaultValue="1" style={{ width: 150 }} onChange={this.handleChangeStatus.bind(this)}>
                        <Option value="1">启用</Option>
                        <Option value="2">禁用</Option>
                    </Select>

                    <Select
                        showSearch
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Select distributor title"
                        optionFilterProp="children"
                        onChange={this.onChange.bind(this)}>
                            {distributorList && Object.keys(distributorList).map((key, i) => (
                                <Option key={i} value={distributorList[key].id }>{ distributorList[key].title }</Option>
                            ))}
                    </Select>
                </div>

                <Table bordered={true} dataSource={this.state.distributorRedirectList} rowKey={row=>row.id}>
                    <Column align="center" title="渠道ID" dataIndex="did" />
                    <Column align="center" title="标题" dataIndex="title" />
                    <Column
                        title="重定向地址"
                        align="center"
                        render={(text, record) => (
                        <a style={this.state.redirectUrlStyle} target="blank" href={text.redirectUrl}>{text.redirectUrl}</a>

                        )}
                    />

                    <Column align="center" title="更新时间" dataIndex="updateTime" />
                    <Column align="center" title="权重值" dataIndex="weight" />
                    <Column
                        title="状态"
                        align="center"
                        render={(text, record) => (
                        <div>{text.status === 1 ? '启用' : '禁用'}</div>
                        )}
                    />
                    <Column
                        title="操作项"
                        key="action"
                        align="center"
                        render={(text, record) => (
                            <div>
                                {/* <Button type="danger">Delete</Button> */}
                                <Divider type="vertical" />
                                <Dropdown overlay={this.menuTemplate()} trigger={['click']}>
                                    <Button onClick={this.getConfigRedirectItem.bind(this, text)}>
                                        更多操作 <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                    />
                </Table>

                {/* start 渠道重定向创建组件 */}
                <DistributorRedirectCreate distributorList={this.state.distributorList} getDistributorRedirectData={this.getDistributorRedirectData.bind(this)} visible={this.state.distributorCreateModelVisible} close={this.createDistributorRedirect.bind(this)}></DistributorRedirectCreate>
                {/*end 渠道重定向创建组件*/}

                {/* start 重定向编辑渠道组件 */}
                <DistributorRedirectEdit getDistributorRedirectData={this.getDistributorRedirectData.bind(this)} distributorConfig={this.state.distributorRedirect} visible={this.state.distributorEditModelVisiblel} close={this.editDistributorRedirect.bind(this)}></DistributorRedirectEdit>
                {/* end  重定向编辑渠道组件*/}
            </div>
        );
    }
}
