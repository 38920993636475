import React from "react";
import axios from 'axios';
import { Table, Switch, Modal, message } from 'antd';
const { Column } = Table;
const { confirm } = Modal;
class DistributorChannel extends React.Component{

    state = {
        channelList: []
    }

    componentDidMount() {//组件已经完全挂载到网页上才会调用被执行
        this.getChannelData();
    }

    /**
     *
     * 获取渠道配置频道信息
     */
    getChannelData() {
      //axios发送post请求;
        axios({
            url: '/api/channel/back/findAllChannelList'
        }).then((res) => {
            let data = res.data;
            if(data.code === 1) {
                this.setState({
                    channelList: data.data
                })
            }
        });
    }

    /**
     * 频道状态，翻新按钮点击事件
     * @param {*} item 
     * @param {*} flag 
     * @param {*} event 
     */

    channelChangeHandle(item, flag, event) {
        let _this = this,
            contentData,
            params = {
                cid: item.id,
                code: item.code,
                title: item.title,
                remark: item.remark,
                enableFlag: item.enableFlag,
                retreadFlag: item.retreadFlag
            };

        if(flag === 'status') {
            console.log(event);
            params.enableFlag = event === true ? 1 : 2;
            contentData = `是否${item.enableFlag === 1 ? '禁用' : '启用'}${item.title}频道状态`;
            if(!event) {
                axios(`/api/distributor/back/findByChannel/?cid=${item.id}`).then((res) => {
                    let data = res.data;
                    if(data.code === 1) {
                        let list = data.data;
                        contentData = `${item.title}   `;
                        list && list.forEach((title, index) => {
                            contentData += `    "${title}"  ,`;
                        });
                        contentData += list ? '与渠道存在绑定关系' : '无绑定关系,确认禁用吗？';
                        confirm({
                            title: `是否${item.enableFlag === 1 ? '禁用' : '启用'}${item.title}频道状态`,
                            content: contentData,
                            onOk() {
                                axios.post('/api/channel/back/updateChannelById', params).then((res) => {
                                    let data = res.data;
                                    if(data.code === 1) {
                                        message.info(`更新成功`);
                                        _this.getChannelData();
                                    }
                                });
                            },
                            onCancel() {},
                        });

                    }
                });

                return;
            }
        }
        if(flag === 'retread') {
            params.retreadFlag = event === true ? 1 : 2;
            contentData = `是否${item.retreadFlag === 1 ? '禁用' : '启用'}${item.title}翻新功能`;
        }
        confirm({
            title: 'prompt',
            content: contentData,
            onOk() {
                axios.post('/api/channel/back/updateChannelById', params).then((res) => {
                    let data = res.data;
                    if(data.code === 1) {
                        message.info(`更新成功`);
                        _this.getChannelData();
                    }
                });
            },
            onCancel() {},
        });
    }

    render() {
        return(
            <div style={{marginTop:'50px'}}>
                <Table bordered={true} dataSource={this.state.channelList} rowKey={row=>row.id}>
                    <Column align="center" title="频道ID" dataIndex="id" />
                    <Column align="center" title="频道编码" dataIndex="code" />
                    <Column align="center" title="频道名称" dataIndex="title" />
                    <Column
                        title="频道状态"
                        align="center"
                        render={(text, record) => (
                            <Switch onClick={this.channelChangeHandle.bind(this, record, 'status')} checkedChildren="启用" unCheckedChildren="禁用" checked={text.enableFlag === 1 ? true : false} />
                        // <span>{text.enableFlag === 1 ? '启用' : '停用'}</span>
                        )}
                    />
                    <Column
                        title="频道翻新"
                        align="center"
                        render={(text, record) => (
                            <Switch onClick={this.channelChangeHandle.bind(this, record, 'retread')} checkedChildren="启用" unCheckedChildren="停用" checked={text.retreadFlag === 1 ? true : false}/>
                        // <span>{text.retreadFlag === 1 ? '启用' : '停用'}</span>
                        )}
                    />
                    
                </Table>
                
            </div>
        )
    }
}

export default DistributorChannel;
