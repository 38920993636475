import React from "react";
import { Input, Modal, message } from "antd";
import Axios from "axios";
const { TextArea } = Input;

/**
 * 渠道创建组件
 */
class DistributorAds extends React.Component {
  /**
   * 构造方法
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      distributorConfig: this.props.distributorConfig
    };
  }

  /**
   * 点击确认按钮的事件
   */
  handleOk() {
    this.subDistributorEditAds();
    console.log(this.state);
  }

  /**
   * 点击取消按钮的事件
   */
  handleCancel() {
    this.closeModel();
  }

  /**
   * 关闭模态框
   */
  closeModel() {
    this.props.close();
  }

  /**
   * 表单input过滤一下state值
   */
  handleChange = (name, type, e) => {
    const { value } = e.target;

    let data = this.state.distributorConfig;
    let ads = data.adConfig;
    if (type === "id") ads[name].id = value;
    if (type === "adlayerProbabilitys") ads[name].adlayerProbabilitys = value;
    if (type === "interval") ads[name].interval = value;
    if (type === "adClickTime") ads[name].adClickTime = value;
    if (type === 'prohibitTime') ads[name].prohibitTime = value;

    this.setState({
      distributorConfig: data
    });
  };

  subDistributorEditAds() {
    let param = this.state.distributorConfig;
    Axios.post("/api/distributor/back/updateDistributor", param).then(res => {
      let data = res.data;
      if (data.code === 1) {
        message.success("渠道广告修改成功！");
        this.props.getDistributorData();
        this.closeModel();
      } else {
        message.error(`${data.msg}`);
      }
    });
  }

  getAdTitle(name) {
    let title = "";
    if (name === "openscreen") title = "开屏广告";
    if (name === "switchChannelPop") title = "切换频道弹窗";
    if (name === "switchChannelPopRenderself") title = "切换频道弹窗(自渲染)";
    if (name === "listTop") title = "列表页顶部";
    if (name === "listStart") title = "列表页首条";
    if (name === "listMiddle") title = "列表页中部";
    if (name === "listBottomFloat") title = "列表页底部浮层";
    if (name === "detailTop") title = "详情页-顶部";
    if (name === "detailNewsStart") title = "详情页-文首";
    if (name === "detailNewsMiddle") title = "详情页-文中";
    if (name === "detailNewsEnd") title = "详情页-文尾";
    if (name === "detailBottomFloat") title = "详情页-底部浮层";
    if (name === "detailNextPageDown") title = "详情页-翻页按钮下";
    if (name === "detailFeed") title = "详情页-Feed流广告";
    if (name === "detailPop") title = "详情页-弹窗(非自渲染)";
    if (name === "detailPopRenderself") title = "详情页-弹窗(自渲染)";
    if (name === "detailFanshiWenzilian") title = "详情页-范式文字链";
    if (name === "buoy") title = "浮点广告";
    if (name === "openscreenRenderself") title = "开屏自渲染广告";
    if (name === "listTopFloat") title = "列表页顶部悬浮广告位";
    if (name === "detailTopFloat") title = "详情页顶部悬浮广告位";
    if (name === "listRightFeedCenrer") title = "频道页推荐-中部(PC)";
    if (name === "listRightFeedTop") title = "频道页推荐-顶部(PC)";
    if (name === "listRightFeedBottom") title = "频道页推荐-底部(PC)";
    if (name === "detailLeftTop") title = "热点聚焦-顶部(PC)";
    if (name === "detailLeftBottom") title = "热点聚焦-底部(PC)";
    if (name === "detailLeftCenter") title = "热点聚焦-中部(PC)";
    return title;
  }

  isDisabled(type) {
    return typeof type == "undefined";
  }

  componentWillReceiveProps(prevProps) {
    // 在组件完成更新后立即调用。在初始化时不会被调用。
    let config = prevProps.distributorConfig;
    if (config.adConfig) {
      this.setState({
        distributorConfig: config
      });
    }
  }

  /**
   * 渲染组件
   */
  render() {
    const adConfig = this.state.distributorConfig.adConfig;
    return (
      <div>
        {/*修改广告模态框*/}
        <Modal
          title="广告配置"
          width={920}
          visible={this.props.visible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.closeModel.bind(this)}
        >
          <table className="table" border="1">
            <tbody>
              <tr>
                <th>广告名称</th>
                <th>广告编号</th>
                <th>插入间隔</th>
                <th>JS广告封禁次数</th>
                <th>JS广告封禁时间(单位: /分)</th>
                <th>广告蒙层概率([1-100])</th>
              </tr>

              {adConfig &&
                Object.keys(adConfig).map((key, i) => (
                  <tr key={i}>
                    <td>{this.getAdTitle(key)}</td>
                    <td>
                      <TextArea
                        onChange={this.handleChange.bind(this, key, "id")}
                        value={`${adConfig[key].id}`}
                        autoSize={{ minRows: 1}}
                      />
                    </td>
                    <td>
                      <TextArea
                        onChange={this.handleChange.bind(this, key, "interval")}
                        value={`${adConfig[key].interval || ""}`}
                        disabled={this.isDisabled(adConfig[key].interval)}
                        autoSize={{ minRows: 1}}
                      />
                    </td>
                    <td>
                      <TextArea
                        onChange={this.handleChange.bind(
                          this,
                          key,
                          "adClickTime"
                        )}
                        value={`${adConfig[key].adClickTime || ""}`}
                        disabled={this.isDisabled(adConfig[key].adClickTime)}
                        autoSize={{ minRows: 1}}
                      />
                    </td>
                    <td>
                      <TextArea
                        onChange={this.handleChange.bind(
                          this,
                          key,
                          "prohibitTime"
                        )}
                        value={`${adConfig[key].prohibitTime || ""}`}
                        disabled={this.isDisabled(adConfig[key].prohibitTime)}
                        autoSize={{ minRows: 1}}
                      />
                    </td>
                    <td>
                      <TextArea
                        onChange={this.handleChange.bind(
                          this,
                          key,
                          "adlayerProbabilitys"
                        )}
                        value={`${adConfig[key].adlayerProbabilitys || ""}`}
                        disabled={this.isDisabled(
                          adConfig[key].adlayerProbabilitys
                        )}
                        autoSize={{ minRows: 1}}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal>
      </div>
    );
  }
}

export default DistributorAds;
