import LayoutView from "../components/Layout/Layout.js";
import Login from '../components/Login/Login.js';
import {Redirect} from "react-router";
import React from "react";
import DistributorConfig from "../components/Distributor/DistributorConfig";
import FrontStaticResourceHoc from "../components/FrontStaticResource/FrontStaticResource";
import DistributorRedirect from '../components/DistributorRedirect/DistributorRedirect';
import ChannelConfig from "../components/Channel/ChannelConfig";
import BannedKeyword from "../components/BannedKeyword/keyword.js";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={"/login"}/>,
    requiresAuth: false
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    requiresAuth: false
  },
  {
    component: LayoutView,
    routes: [
      {
        path: "/distributorConfig",
        component: DistributorConfig,
        requiresAuth: true
      },
      {
        path: "/frontStaticResource",
        component: FrontStaticResourceHoc,
        requiresAuth: true
      },
      {
        path: "/channelconfig",
        component: ChannelConfig,
        requiresAuth: true
      },
      {
        path: "/distributorRedirect",
        component: DistributorRedirect,
        requiresAuth: true
      },
      {
        path: "/bannedKeyword",
        component: BannedKeyword,
        requiresAuth: true
      }

    ]
  },
];

export default routes;
