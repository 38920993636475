import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, message } from 'antd';
import Axios from "axios";

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {xs: { span: 24 },  sm: { span: 4 }},
    wrapperCol: {xs: { span: 24 }, sm: { span: 20 }},
};

/**
 * 渠道创建组件
 */
class DistributorDetailPage extends React.Component{

    /**
     * 构造方法
     * @param props
     */
    constructor(props){
        super(props)
        this.state = {

        }
    }

    /**
     * 点击确认按钮的事件
     */
    handleOk(){
        this.subDistributorEdit();
    }

    /**
     * 点击取消按钮的事件
     */
    handleCancel(){
        this.closeModel();
    }

    /**
     * 关闭模态框
     */
    closeModel(){
        this.props.close();
    }

    /**
     * 表单input过滤一下state值
     */
    handleChange = (name, e) => {
        const { value } = e.target;
        let data = this.state.distributorConfig;
        data[name] = value;

        this.setState({
            distributorConfig: data
        });
    }

    subDistributorEdit() {
        let param = this.state.distributorConfig;

        Axios.post('/api/distributor/back/updateDistributor', param).then((res) => {
          let data = res.data;
          if(data.code === 1) {
            message.success('渠道信息修改成功！');
            // 创建成功后请求渠道信息；
            this.props.getDistributorData();
            //关闭模态框
            this.closeModel();
          } else {
            message.error(`${data.msg}`);
          }
        });
    }

    componentWillReceiveProps(prevProps) {// 在组件完成更新后立即调用。在初始化时不会被调用。
        let config = prevProps.distributorConfig;
        if(config.id && config.title) {
            this.setState({
                distributorConfig: config
            })
        }
    }

    /**
     * 渲染组件
     */
    render() {
        if (this.state.distributorConfig && this.state.distributorConfig.code) {
            const config = this.state.distributorConfig
            return (
                <div>
                    {/*渠道编辑模态框*/}
                    <Modal
                        title="详情页入口跳转配置"
                        visible={this.props.visible}
                        onOk={this.handleOk.bind(this)}
                        onCancel={this.handleCancel.bind(this)}>
                        <Form>
                            <Form.Item labelAlign="left" {...formItemLayout} label="渠道标题">
                                <Input disabled={true} value={this.state.distributorConfig.title} onChange={this.handleChange.bind(this, 'title')} placeholder="Please input your title" />
                            </Form.Item>
                            <Form.Item labelAlign="left" {...formItemLayout} label="跳转地址">
                            <TextArea
                                onChange={this.handleChange.bind(this, "detailsPageEntryJump")}
                                value={`${config.detailsPageEntryJump || ""}`}
                                autoSize={{ minRows: 1}}
                            />
                                <p>如有多个跳转地址可使用“,”隔开</p>
                            </Form.Item>
                        </Form>

                    </Modal>
                </div>
            )
          } else {
            return null;
        }

    }
}

export default DistributorDetailPage
